html,
body,
#root,
.app-page {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

/* 滚动条优化 start */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius:2px;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: #666;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}




