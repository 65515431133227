.ba-layout-app,
.ba-layout-body {
    height: 100%;
    width: 100%
}

.ba-layout-sider{
    /* background-color: transparent; */
    overflow-y: auto;
}
.ba-layout-header {
    /* background-color: #fff; */
    /* border-bottom: 1px solid #f0f0f0; */
    padding: 0;
    height: 48;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: transparent; */
    
}

.data-table-query-form>.ant-col {
    margin-bottom: 15px;
}

.data-table-query-form>:last-child{
    margin-bottom: 0;
}